/** Request 网络请求工具 更详细的 api 文档: https://github.com/umijs/umi-request */
import {extend} from 'umi-request';
import {Toast} from 'antd-mobile';


const codeMessage: { [key: number]: string } = {
    200: '服务器成功返回请求的数据。',
    201: '新建或修改数据成功。',
    202: '一个请求已经进入后台排队（异步任务）。',
    204: '删除数据成功。',
    400: '发出的请求有错误，服务器没有进行新建或修改数据的操作。',
    401: '用户没有权限（令牌、用户名、密码错误）。',
    403: '用户得到授权，但是访问是被禁止的。',
    404: '发出的请求不存在，服务器没有进行操作。',
    406: '请求的格式不可得。',
    410: '请求的资源被永久删除，且不会再得到的。',
    422: '当创建一个对象时，发生一个验证错误。',
    500: '服务器发生错误，请检查服务器。',
    502: '网关错误。',
    503: '服务不可用，服务器暂时过载或维护。',
    504: '网关超时。',
};
// 异常处理程序
const errorHandler = (error: { response: Response }): Response => {
    const {response} = error;
    if (response && response.status) {
        const errorText = codeMessage[response.status] || response.statusText;
        const {status, url} = response;
        if (status !== 200) {
            Toast.show({
                icon: 'fail',
                content: `请求错误 ${status}: ${errorText}`,  // ${url}
            });
        }
    } else if (!response) {
        Toast.show({
            icon: 'fail',
            content: '您的网络发生异常，无法连接服务器',
        });
    }

    return response;
};

// 配置request请求时的默认参数
const request = extend({
    errorHandler, // 默认错误处理
    // credentials: 'include', // 默认请求是否带上cookie
});

request.interceptors.request.use((url, options) => {
    const { headers } = options;
    console.log(url);
    return {
        url,
        options: {
            ...options,
            data: {
                ...options.data,
            },
            headers: headers,
        },
    };
});

request.interceptors.response.use(async (response) => {
    try {
        const data = await response.clone().json();
        // 移除了处理 401 状态码的逻辑
        if (data && data.code === 401) {
            Toast.show({
                icon: 'fail',
                content: `请求错误 ${data.code}: 你没有权限使用此功能`,
            });
        }
    } catch (e) {
        if (response.url.indexOf('/artisanExecute/syncParams') === -1) {
            throw e;
        }
    }
    return response;
});

export default request;
