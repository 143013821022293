import React from 'react';
import './App.css';
import StepManager from './components/StepManager';

function App() {
  return (
        <StepManager />
  );
}

export default App;
