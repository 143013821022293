// src/components/StepManager.tsx
import React, { useState } from 'react';
import OrderVerification from './OrderVerification';
import ImageUpload from './ImageUpload';
import CouponLink from './CouponLink';
import { NavBar } from "antd-mobile";

const StepManager: React.FC = () => {
    const [currentStep, setCurrentStep] = useState<number>(0);
    const [couponLink, setCouponLink] = useState<string>('https://shop147289177.youzan.com/wscump/coupon/fetch?alias=1f0r0p2xv&sign=b5bf61ffc31a355af8f0bec1d7e3a051&shopAutoEnter=1');
    const [queryRecordId, setQueryRecordId] = useState<bigint | null>(null);

    const handleNextStep = () => {
        // Implement API calls for each step transition
        if (currentStep === 0) {
            // Call API for order verification
            // If order is valid, set couponLink and move to next step
            // Replace the following line with actual API call
            setCouponLink('https://example.com/coupon');
        } else if (currentStep === 1) {
            // Call API for image upload
            // Move to the next step on successful upload
            // Replace the following line with actual API call
            setCurrentStep(currentStep + 1);
        } else {
            // Handle the final step
            // You may want to perform some final actions or cleanup here
        }
    };

    const handleUploadImage = (id: bigint) => {
        // Implement any logic needed before moving to the next step
        setQueryRecordId(id);
        setCurrentStep(currentStep + 1);
    };

    const handleUploadComplete = () => {
        // Implement any logic needed before moving to the next step
        setCurrentStep(currentStep + 1);
    };

    return (
        <div className="mainBlock">
            <div className="main">
                <NavBar back='' backArrow={false}>免费兑换奖励</NavBar>
            </div>

            {currentStep === 0 && <OrderVerification onUploadImage={handleUploadImage} />}
            {currentStep === 1 && <ImageUpload queryRecordId={queryRecordId} onUploadComplete={handleUploadComplete} />}
            {currentStep === 2 && <CouponLink couponLink={couponLink} />}
        </div>
    );
};

export default StepManager;
