import request from '../utils/request';

// 获取采购商品列表
export async function getQueryRecord(params: any) {
    return request('/api/queryRecord/queryTradeNo', {
        method: 'POST',
        data: params,
    });
}
export async function uploadImage(formData: FormData) {
    return request( '/api/upload/order/file', {
        method: 'POST',
        headers: {
            // 如果是文件上传，需要设置 Content-Type 为 undefined 或不设置
            'Content-Type': 'multipart/form-data',
        },
        body: formData,
    });
}

export async function updateQueryRecord(params: any) {
    return request('/api/queryRecord/updateQueryRecord', {
        method: 'POST',
        data: params,
    });
}