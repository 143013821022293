// src/components/CouponLink.tsx
import React from 'react';
import {Steps, Button, Toast, AutoCenter, Image, Space, Divider} from 'antd-mobile';

interface CouponLinkProps {
    couponLink: string;
}

const CouponLink: React.FC<CouponLinkProps> = ({couponLink}) => {
    const handleClaimCoupon = () => {
        // Implement logic to open the coupon link in a new window or redirect the user
        Toast.show({
            icon: 'success',
            content: '点击链接转到小程序领取优惠券',
        })
        window.location.href = couponLink;
    };

    return (
        <div>
            <Steps current={2} style={{marginTop: "20px", marginBottom: 40}}>
                <Steps.Step title='验证订单号'/>
                <Steps.Step title='提交订单截图'/>
                <Steps.Step title='领取奖励'/>
            </Steps>
            <AutoCenter className="bgWhite">
                <Space direction='vertical' style={{marginTop:32,marginBottom:32,marginLeft:16,marginRight:16}}>
                    <Image src="discount_poster.png"/>
                </Space>
            </AutoCenter>
            <Divider>OR</Divider>
            <Button block={true} type='submit' color='primary' size='large' onClick={handleClaimCoupon}
                    style={{marginTop: 20}}>
                点击领取优惠券
            </Button>
        </div>
    );
};

export default CouponLink;
