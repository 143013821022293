// src/components/OrderVerification.tsx
import React, { useState } from 'react';
import { Form,Input, Button, Toast, Space, Steps,Divider, Dialog} from 'antd-mobile';
import {getQueryRecord} from '../services/api';
import { ExclamationCircleFill } from 'antd-mobile-icons'
interface OrderVerificationProps {
    onUploadImage: (queryRecordId: bigint) => void;
}

const OrderVerification: React.FC<OrderVerificationProps> = ({ onUploadImage }) => {
    const [loading, setLoading] = useState<boolean>(false);
    const handleVerifyOrder = async (values: any) => {
        setLoading(true);
        try {
            // 调用后台 API 验证订单
            const response = await getQueryRecord(values);
            if(response.success === true && response.data.id){
                // 跳转到下一步
                onUploadImage(response.data.id);
            }else{
                Dialog.alert({
                    header: (
                        <ExclamationCircleFill
                            style={{
                                fontSize: 64,
                                color: 'var(--adm-color-warning)',
                            }}
                        />
                    ),
                    title: '错误',
                    content: (<div style={{textAlign:'center'}} dangerouslySetInnerHTML={{ __html: response.errorMessage }} />),
                })
            }
            // 处理验证成功的逻辑，这里可以根据后端返回的数据进行判断
            setLoading(false);
        } catch (error: any) {
            Toast.show({icon: 'fail',content:'订单验证失败，请检查订单号'});
            setLoading(false);
        }
    };

    return (
        <div>
            <Steps current={0} style={{ marginTop: "20px", marginBottom: 40 }}>
                <Steps.Step title='验证订单号' />
                <Steps.Step title='提交订单截图' />
                <Steps.Step title='领取奖励' />
            </Steps>

            <Form
                onFinish={handleVerifyOrder}
                initialValues={{
                    tradeNo: '',
                }}
                layout='horizontal'
                footer={
                    <Button block={true} type='submit' color='primary' size='large' disabled={loading}>
                        {loading ? '正在提交...' : '验证订单'}
                    </Button>
                }
                style={{ marginTop: 48 }}
            >
                <Form.Header>请将电商平台上的订单号填写在这里</Form.Header>
                <Form.Item name='tradeNo' label='订单号' rules={[{ required: true }]}>
                    <Input placeholder='请输入订单号' />
                </Form.Item>
            </Form>
        </div>
    );
};

export default OrderVerification;
