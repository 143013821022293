// src/components/ImageUpload.tsx
import React, { useState, useEffect } from 'react';
import { Button, Steps, Toast, Dialog, AutoCenter, Space, SpinLoading } from 'antd-mobile';
import ImageUploader, { ImageUploadItem } from 'antd-mobile/es/components/image-uploader';
import Resizer from 'react-image-file-resizer';
import {updateQueryRecord, uploadImage} from "../services/api";

interface ImageUploadProps {
    queryRecordId: bigint|null;
    onUploadComplete: () => void;
}

const ImageUpload: React.FC<ImageUploadProps> = ({ queryRecordId,onUploadComplete }) => {
    const [fileList, setFileList] = useState<ImageUploadItem[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        if (loading) {
            Toast.show({
                icon: 'loading',
                content: '加载中…',
                maskClickable: false,
            });
        } else {
            Toast.clear();
        }
    }, [loading]);

    const handleUploadComplete = () => {
        onUploadComplete();
    };

    const handleImageUpload = async (file: File) => {
        try {
            const compressedImage = await compressAndSetImage(file);
            return Promise.resolve(compressedImage);
        } catch (error) {
            return Promise.reject(error);
        }
    };

    const compressAndSetImage = (file: File): Promise<ImageUploadItem> => {
        const maxSize = 800;
        const quality = 80;

        setLoading(true);

        return new Promise((resolve, reject) => {
            Resizer.imageFileResizer(
                file,
                maxSize,
                maxSize,
                'JPEG',
                quality,
                0,
                async (uri) => {
                    try {
                        const compressedImage = await dataURItoBlob(uri);

                        const newItem: ImageUploadItem = { url: URL.createObjectURL(compressedImage) };
                        setFileList([newItem]);
                        setLoading(false);
                        resolve(newItem);
                    } catch (error) {
                        setLoading(false);
                        reject(error);
                    }
                },
                'base64'
            );
        });
    };

    const dataURItoBlob = async (dataURI: string | File | Blob | ProgressEvent<FileReader>): Promise<Blob> => {
        if (dataURI instanceof File) {
            return new Promise(resolve => {
                const reader = new FileReader();
                reader.onloadend = () => {
                    resolve(dataURItoBlob(reader.result as string));
                };
                reader.readAsDataURL(dataURI);
            });
        }

        const byteString = atob(dataURI.toString().split(',')[1]);
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);

        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        return Promise.resolve(new Blob([ab], { type: 'image/jpeg' }));
    };

    //保存图片
    const handleSaveImage = async (imagePath: string, fileName: string) => {
        try {
            const response = await updateQueryRecord({
                queryRecordId: String(queryRecordId),
                imagePath,
                fileName,
            });

            if (response.success === true) {
                Toast.show({
                    icon: 'success',
                    content: (<div style={{textAlign:'center'}} dangerouslySetInnerHTML={{ __html: response.message }} />),
                });
            } else {
                Toast.show({
                    icon: 'error',
                    content: (<div style={{textAlign:'center'}} dangerouslySetInnerHTML={{ __html: response.errorMessage }} />),
                });
                return false;
            }
        } catch (error) {
            Toast.show({
                icon: 'error',
                content: '请求失败，请稍后重试',
            });
            return false;
        }
        return true;
    };

    const handleNextStep = async () => {
        if (fileList.length > 0) {
            setLoading(true);

            const formData = new FormData();
            const fileName = `image_${Date.now()}.jpg`; // 你可以根据需要调整扩展名
            formData.append('file', await fetchBlobFromImageURL(fileList[0].url), fileName);
            try {
                const response = await fetch('/api/upload/order/file', {
                    method: 'POST',
                    body: formData,
                });
                // const response = await uploadImage(formData);
                console.log(formData);
                console.log(response);
                if (response.ok) {
                    Toast.show({
                        icon: 'success',
                        content: '订单截图上传成功',
                    });
                    // 保存图片信息
                    const { pathname, name } = await response.json();
                    if(await handleSaveImage(pathname, name)){
                        // 执行下一步逻辑
                        onUploadComplete();
                    }
                } else {
                    Toast.show({
                        icon: 'error',
                        content: '订单截图上传失败，请稍后重试',
                    });
                }
            } catch (error) {
                Toast.show({
                    icon: 'error',
                    content: '请求失败，请稍后重试',
                });
            } finally {
                setLoading(false);
            }
        }
    };

    const fetchBlobFromImageURL = async (url: string): Promise<Blob> => {
        const response = await fetch(url);
        const blob = await response.blob();
        return blob;
    };

    const handleDeleteImage = () => {
        return Dialog.confirm({
            content: '是否确认删除',
        });
    };

    return (
        <>
            <Steps current={1} style={{ marginTop: '20px', marginBottom: 40 }}>
                <Steps.Step title='验证订单号' />
                <Steps.Step title='提交包裹照片' />
                <Steps.Step title='领取奖励' />
            </Steps>
            <div className="titleHeader">请对包裹进行拍照，需要将卡片拍摄进来</div>
            <AutoCenter style={{ backgroundColor: '#fff', paddingTop: 24, paddingBottom: 24 }}>
                {loading ? (
                    <Space direction='horizontal' wrap block style={{ '--gap': '16px' }}>
                        <SpinLoading style={{ '--size': '48px' }} />
                    </Space>
                ) : (
                    <ImageUploader
                        accept={'image/*'}
                        value={fileList}
                        onChange={setFileList}
                        upload={handleImageUpload}
                        onDelete={handleDeleteImage}
                        maxCount={1}
                        multiple={false}
                        columns={1}
                        style={{ '--cell-size': '200px' }}
                    />
                )}
            </AutoCenter>
            <Button
                block={true}
                color='primary'
                size='large'
                onClick={handleNextStep}
                style={{ marginTop: 20 }}
                disabled={loading || fileList.length === 0}
            >
                提交订单截图
            </Button>
        </>
    );
};

export default ImageUpload;
